import de from '/lang/de.json'
import en from '/lang/en.json'

// You can use `defineI18nConfig` to get type inferences for options to pass to vue-i18n.
export default defineI18nConfig(() => {
  return {
    globalInjection: true,
    legacy: true,
    locale: 'de',
    lazy: true,
    messages: {
      en,
      de
    }
  }
})
